import React, { useMemo, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useMatch,
} from "react-router-dom";
import { RecoilRoot, useSetRecoilState } from "recoil";

import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { useDoubleTap } from "use-double-tap";
import * as math from "mathjs";

import "./App.css";

import { VisualOptions } from "./types/types";
import { useAudio } from "./hooks/audio";
import EntryPoint from "./components/EntryPoint";
import useFullscreen from "./hooks/fullscreen";
import Warp from "./components/visuals/Warp";
import Particle from "./components/visuals/Particles";
import { useSettingsFromRoute } from "./hooks/routeSettings";
import { useSettingsManager } from "./hooks/settings";
import { fpsState } from "./components/widgets/FPS";
import { BASE_AUDIO_LENGTH } from "./gfx/warpgrid/params";
import MenuPanel from "./components/MenuPanel";
import ParticleLife from "./components/visuals/ParticleLife";

const useStyles = makeStyles({
  app: {
    background: "linear-gradient(135deg, #45484d 0%,#000000 100%)",
    minHeight: "100vh",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
  },
  errorDisplay: {
    background: "linear-gradient(-180deg, #CFC5B4 0%,#ab7a4752 267%)",
    padding: "2em",
  },
  errorMessage: {
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  canvas: {
    width: "100vw",
    height: "100vh",
  },
});

const buckets = 32;
const length = BASE_AUDIO_LENGTH;
const gridSize = { width: 128, height: 64 };

const useVisualFromRoute = () => {
  const match = useMatch("/:visual");
  if (match) return match.params.visual as VisualOptions;
};

const useBackgroundImage = () => {
  const images = [
    "peragwin_shamanic_ceremony_rave_music_dmt_datacenter_intricate__08de44eb-8e3e-44ec-b83c-6d67debd76c2.png",
    "peragwin_shamanic_ceremony_inside_a_dmt_datacenter_temple_intri_5ac96cd8-d5e5-4ee7-83b3-d22da43dc6da.png",
    "peragwin_photo_of_a_brick_wall_in_a_back_alleyway_with_graffiti_f5b7e06b-8677-44c0-942f-cb0f6f14ae52.png",
    "peragwin_glowing_psychedelic_forest_alien_dwellings_474bc1ee-3c32-40e4-9ab5-dab945f2ec41.png",
    "peragwin_endless_holographic_void_c9dfb2eb-8dc8-4913-97e8-ae580a9b7932.png",
    "peragwin_endless_holographic_void_10c59d31-0454-4580-821a-a094f172f0a9.png",
    "peragwin_cyber_laser_virus_infectious_pathogen_alien_planet_57ac9865-f58b-4fcb-9cc9-2ca844e37c04.png",
    "peragwin_cinematic_alien_desert_planet_open_sky_psychedelic_oas_58615217-e78f-428a-8619-4bf6e8676807.png",
    "peragwin_ancient_divine_magic_psychedelic_intricate_fractal_syn_9aadd2cd-167f-4f14-97be-713d82c13085.png",
    "peragwin_alien_world_dmt_ceremony_inside_a_temple_with_many_cre_a9df9807-d766-4c1d-a3e1-c23fe4550c4d.png",
    "peragwin_alien_world_dmt_ceremony_inside_a_temple_with_many_cre_1e202c7d-2cf9-4d02-a652-2926f195b80c.png",
    "peragwin_a_cinematic_city_of_living_intricate_glowing_imaginati_a3bb7de3-70c1-4331-a12e-e01843e4a7f0.png",
    "peragwin_a_cinematic_city_of_living_intricate_glowing_imaginati_607d7053-32c7-4691-a5ac-9e5fb4da4270.png",
    "peragwin_8k_detailed_cinematic_domed_cyberpunk_city_defending_a_da5df01c-0e87-4835-9759-e5a9f23ec02b.png",
  ];
  const seed = useMemo(() => math.randomInt(0, images.length), []);
  console.log(seed);
  return {
    backgroundImage: `url(background/${images[seed]})`,
    backgroundPosition: "center",
  };
};

const App: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const captureCanvas = () =>
    canvasRef.current && canvasRef.current.toDataURL();

  const handleFullscreen = useFullscreen();
  const doubleTapBinding = useDoubleTap((ev) => {
    handleFullscreen(ev);
  });

  const audioController = useAudio({
    window: 1024,
    frame: 256,
    buckets,
    length,
  });

  const visual = useVisualFromRoute();
  const [warpController, ppsController, particleLifeController, manager] =
    useSettingsManager(
      audioController,
      { width: length, height: buckets },
      gridSize
    );
  useSettingsFromRoute(visual, manager);

  const [errorState, setErrorState] = useState<Error | null>(null);
  const setFrameRate = useSetRecoilState(fpsState);

  const classes = useStyles();

  // this is super hacky lol
  if (visual) {
    document.body.scrollTop = 0;
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflowY = "scroll";
    document.body.style.overflowX = "hidden";
  }

  const backgroundImage = useBackgroundImage();

  return (
    <div className={classes.app} style={backgroundImage}>
      {errorState ? (
        <div>
          <Paper className={classes.errorDisplay} elevation={3}>
            <Typography variant="h2">
              <span role="img" aria-label="shit">
                💩
              </span>
              ... Vuzic was unable to load
            </Typography>
            <pre className={classes.errorMessage}>{errorState.message}</pre>
          </Paper>
        </div>
      ) : (
        <React.Fragment>
          {visual && (
            <div>
              <MenuPanel
                visual={visual}
                manager={manager}
                captureCanvas={captureCanvas}
                handleFullscreen={handleFullscreen}
              />
              <canvas
                ref={canvasRef}
                className={classes.canvas + " render-canvas"}
                // onDoubleClick={handleFullscreen}
                {...doubleTapBinding}
              />
            </div>
          )}

          <Routes>
            <Route path="/" element={<EntryPoint />} />

            <Route
              path="/warp"
              element={
                <Warp
                  canvas={canvasRef}
                  audio={audioController}
                  controller={warpController}
                  setErrorState={setErrorState}
                  setFrameRate={setFrameRate}
                />
              }
            />

            <Route
              path="/pps"
              element={
                <Particle
                  canvas={canvasRef}
                  audio={audioController}
                  controller={ppsController}
                  setErrorState={setErrorState}
                  setFrameRate={setFrameRate}
                />
              }
            />

            <Route
              path="/pps3"
              element={
                <Particle
                  canvas={canvasRef}
                  audio={audioController}
                  controller={ppsController}
                  setErrorState={setErrorState}
                  setFrameRate={setFrameRate}
                  mode={"3D"}
                />
              }
            />

            <Route
              path="/particleLife"
              element={
                <ParticleLife
                  canvas={canvasRef}
                  audio={audioController}
                  controller={particleLifeController}
                  setErrorState={setErrorState}
                  setFrameRate={setFrameRate}
                />
              }
            />
          </Routes>
        </React.Fragment>
      )}
    </div>
  );
};

const theme = createTheme({});

export default () => (
  <RecoilRoot>
    <Router>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  </RecoilRoot>
);
